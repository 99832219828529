// jQuery
import $ from "jquery"

// KV Amimation
$(window).on("load", function () {
  $(".p-kv-top__bg-image").fadeIn(1000)
  $(".p-menu-btn").fadeIn(1000)
  setTimeout(function () {
    $(".p-nav").removeClass("hidden")
  }, 100) // ロードした時にメニューが先に表示されるのを防ぐ処理
  setTimeout(function () {
    $(".js-logo").fadeIn(1000)
  }, 1000)
})
// /KV Amimation

// Hamburger menu

$(function () {
  var js_content_body = $("#js-contents-body").offset().top
  var switch_flag = false // スクロール値によって切り替えるスイッチ

  $(window).on("scroll", function () {
    var scroll = $(window).scrollTop()
    if (scroll >= js_content_body) {
      if ($(".p-nav").not("active")) {
        $(".openbtn").addClass("black")
      } else {
        $(".openbtn").removeClass("black")
      }
      switch_flag = true
    } else {
      if ($(".p-nav").hasClass("active")) {
        $(".openbtn").addClass("black")
      } else {
        $(".openbtn").removeClass("black")
      }

      switch_flag = false
    }
  })

  $(window).on("load", function () {
    var scroll = $(window).scrollTop()
    if (scroll >= js_content_body) {
      $(".openbtn").addClass("black")
    }
  })

  $(".openbtn").click(function () {
    if (switch_flag) {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active")
        $(".p-menu-btn__ttl").text("MENU")
        $(".js-logo").show()
      } else if ($(this).not("active")) {
        $(this).addClass("active")
        $(".p-menu-btn__ttl").text("CLOSE")
      }
    } else {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active")
        $(".p-menu-btn__ttl").text("MENU")
        $(this).removeClass("black")
        $(".js-logo").show()
      } else {
        $(this).addClass("active")
        $(this).addClass("black")
        $(".p-menu-btn__ttl").text("CLOSE")
        $(".js-logo").hide()
      }
    }

    if ($(".p-nav").hasClass("active")) {
      $(".p-nav").removeClass("active")
      $(".p-nav__item").addClass("offset")
    } else {
      $(".p-nav").addClass("active")
      for (let i = 1; i < 7; i++) {
        var time = i + "00"
        setTimeout(function () {
          $(".p-nav__item--" + i).removeClass("offset")
        }, time)
      }
    }
  })

  $(".p-nav__base").click(function () {
    $(".p-nav").removeClass("active")
    $(".openbtn").removeClass("active")
    $(".p-menu-btn__ttl").text("MENU")
    $(".js-logo").show()
    if (switch_flag === false) {
      $(".openbtn").removeClass("black")
    }
  })
  $("#js-nav-profile").click(function () {
    $(".p-nav").removeClass("active")
    $(".openbtn").removeClass("active")
  })
})

// /Hamburger menu

// /jQuery

// simpleParallax
import simpleParallax from "simple-parallax-js"
var image = document.getElementsByClassName("thumbnail")
new simpleParallax(image, {
  scale: 1.2,
})

// /simpleParallax

import "slick-carousel"

$(".variable-width").slick({
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
})
